
.my-row {
	border-color: gray;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	padding: 10px 0;
}

.my-row-label {
	color: wheat;
	font-size: 14px;
	font-weight: bold;
}