html {
	background-color: #282c34;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #282c34;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

.g-clear-link, .g-clear-link:hover {
	text-decoration: none;
	color: black;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-accent { color: #03dac4; }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.mdi.mdi-18 { font-size: 18px; }
.mdi.mdi-24 { font-size: 24px; }
.mdi.mdi-36 { font-size: 36px; }
.mdi.mdi-48 { font-size: 48px; }
.mdi.mdi-dark { color: rgba(0, 0, 0, 0.54); }


.g-pointer { cursor: pointer; }
.g-padding-6-10 { padding: 6px 10px; }
.g-margin-t-10 { margin-top: 10px; }
.g-margin-t-20 { margin-top: 20px; }
.g-margin-b-20 { margin-bottom: 20px; }
.g-text-align-center { text-align: center; }
.g-flex-h-c-c {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.g-flex-h-sb-c {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.g-flex-h-fs-c {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.g-flex-h-sa-c {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.g-flex-h-sb-fe {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}



.g-empty-list-reminder {
    padding: 6px 10px;
    text-align: center;
    color: wheat;
}







.mdi::before {
    font-size: 24px;
    line-height: 14px;
}
.btn .mdi::before {
    position: relative;
    top: 4px;
}
.btn-xs .mdi::before {
    font-size: 18px;
    top: 3px;
}
.btn-sm .mdi::before {
    font-size: 18px;
    top: 3px;
}
.dropdown-menu .mdi {
    width: 18px;
}
.dropdown-menu .mdi::before {
    position: relative;
    top: 4px;
    left: -8px;
}
.nav .mdi::before {
    position: relative;
    top: 4px;
}
.navbar .navbar-toggle .mdi::before {
    position: relative;
    top: 4px;
    color: #FFF;
}
.breadcrumb .mdi::before {
    position: relative;
    top: 4px;
}
.breadcrumb a:hover {
    text-decoration: none;
}
.breadcrumb a:hover span {
    text-decoration: underline;
}
.alert .mdi::before {
    position: relative;
    top: 4px;
    margin-right: 2px;
}
.input-group-addon .mdi::before {
    position: relative;
    top: 3px;
}
.navbar-brand .mdi::before {
    position: relative;
    top: 2px;
    margin-right: 2px;
}
.list-group-item .mdi::before {
    position: relative;
    top: 3px;
    left: -3px
}